.css-bg1rzq-control{
	cursor: pointer !important;
}
.css-1omcacj-container:focus-within .css-151xaom-placeholder{
	color: hsla(0, 0%, 50%, 0) !important;
}
.css-151xaom-placeholder{
	margin-left: 5px !important;
}
/* #busqueda-destino > .css-bg1rzq-control > .css-1k68xlo-singleValue > .css-1hwfws3{ */
#busqueda-destino >div >.css-1hwfws3 >.css-1k68xlo-singleValue{
	margin-left: 5px !important;
}